<template>
  <div class="container-fluid">
    <div class="row">
      <div class="container-fluid">
        <b-card
          class="col-lg-12"
          style="margin-top: 10px; margin-bottom: 10px"
        >
          <b-row
            align-v="center"
            class="justify-content-between"
          >
            <template v-if="$store.state.userState.policies.includes('最高權限')">
              <b-col class="col-2">
                <b-button :href="download()"
                          size="lg"
                          variant="dark"
                >點擊下載借用紀錄
                </b-button>
              </b-col>
              <b-col class="col-4">
                <b-form-select
                  v-model="excelItemType"
                  :options="itemOptions"
                />
                <b-form-checkbox v-model="isApplying">不包含申請中的紀錄</b-form-checkbox>
                <b-form-checkbox v-model="active">不包含已過期的紀錄</b-form-checkbox>
              </b-col>
            </template>
            <b-col>
              <b-button
                :variant="lock ? 'warning' : 'primary'"
                class="float-right"
                size="lg"
                @click="lock = !lock"
              >{{ lock ? "解鎖恢復" : "鎖定恢復" }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <div class="col-lg-12 container-fluid">
        <b-row>
          <b-col>
            <b-form-group
              description="輸入學號後按 Enter 搜尋"
              label="歷史紀錄查詢"
              label-size="lg"
            >
              <b-form-input
                v-model="studentId"
                :disabled="loading"
                :placeholder="placeholder"
                @keydown.enter="searchStudentId"
              />
            </b-form-group>
          </b-col>
          <b-col align-self="center">
            <b-card class="text-center">
              <b-card-text
                v-if="user.name"
                text-tag="h4"
              >{{ user.name + " " + user.dorm + user.room + (user.bed ? "-" : "X") + user.bed }}
              </b-card-text>
              <b-card-text
                v-else-if="result === '使用者不存在'"
                text-tag="h4"
                v-text="result"
              >
              </b-card-text>
              <b-card-text v-else>
                <br/>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="justify-content-between">
          <b-input-group class="col-6" prepend="搜尋">
            <b-input-group-prepend>
              <b-form-select
                v-model="search.selected"
                :options="search.options"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="search.word"
              type="text"
            />
            <b-input-group-append>
              <b-button
                variant="dark"
                @click="search.word = ''"
              >重設
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-radio-group
            v-if="records.length"
            v-model="itemType"
            :options="itemOptions"
            button-variant="outline-info"
            buttons
            class="col-auto"
          />
        </b-row>
        <b-table
          v-if="records.length"
          id="searchTable"
          :current-page="currentPage"
          :fields="fields"
          :filter="filter"
          :filter-function="itemFilter"
          :filter-included-fields="filterOn"
          :items="records"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-compare="sortCompare"
          :sort-desc.sync="sortDesc"
          :tbody-tr-class="warn"
          align="middle"
          class="text-center"
          empty-filtered-text="沒有符合的紀錄"
          empty-text="沒有借用紀錄"
          fixed
          hover
          outlined
          show-empty
          striped
          @filtered="onFiltered"
        >
          <template #cell(room)="row">
            {{ row.value === "" ? "非住宿生" : row.value }}
          </template>
          <template #cell(loanDate)="row">
            {{ row.value | formatDate }}
          </template>
          <template #cell(returnDate)="row">
            {{ row.value | formatDate }}
          </template>
          <template #cell(action)="row">
            <template v-if="row.item.status === '已歸還'">
              <b-button
                :disabled="lock"
                variant="warning"

                @click="recover(row.item.recordId)"
              >恢復
              </b-button>
            </template>
          </template>
        </b-table>
        <b-pagination
          v-if="records.length"
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="rows"
          align="fill"
          aria-controls="searchTable"
          size="sm"
        />
        <h3
          v-else-if="user.name"
          v-text="result"/>
        <h3
          v-else-if="result !== '使用者不存在'"
          v-text="result"
        />
        <h3
          v-else
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "borrowManage",
  data() {
    return {
      href: "https://dorm.nttu.club/api/item/export-excel",
      isApplying: false,
      active: false,
      excelItemType: null,
      lock: true,

      search: {
        selected: null,
        options: [
          {text: "全部", value: null},
          {text: "編號", value: "recordId"},
          {text: "學期", value: "serial"},
          {text: "學號", value: "studentId"},
          {text: "姓名", value: "name"},
          {text: "房號", value: "room"},
          {text: "物品", value: "itemType"},
          {text: "名稱", value: "itemName"},
          {text: "證件", value: "credential"},
          {text: "理由", value: "reason"},
          {text: "備註", value: "comment"}
        ],
        word: ""
      },
      filterOn: ["studentId", "itemType"],

      itemType: null,
      itemOptions: [
        {text: "全部", value: null},
        {text: "鑰匙", value: "鑰匙"},
        {text: "早離卡", value: "早離卡"},
        {text: "臨時卡", value: "臨時卡"},
        {text: "推車", value: "推車"},
        {text: "其他", value: "其他"}
      ],

      studentId: "",
      placeholder: "",
      result: "",
      loading: false,
      name: "",
      user: {
        name: "",
        dorm: "",
        room: "",
        bed: ""
      },
      fields: [
        {key: "recordId", label: "紀錄編號", sortable: true, tdClass: "align-middle"},
        {key: "serial", label: "學期", sortable: true, tdClass: "align-middle"},
        {key: "studentId", label: "學號", sortable: true, tdClass: "align-middle"},
        {key: "room", label: "房號", sortable: true, tdClass: "align-middle"},
        {key: "itemType", label: "物品", sortable: true, tdClass: "align-middle"},
        {key: "itemName", label: "編號/名稱", sortable: true, tdClass: "align-middle"},
        {key: "loanDate", label: "借用時間", sortable: true, tdClass: "align-middle"},
        {key: "returnDate", label: "歸還時間", sortable: true, tdClass: "align-middle"},
        {key: "reason", label: "理由", tdClass: "align-middle"},
        {key: "comment", label: "備註", tdClass: "align-middle"},
        {key: "status", label: "狀態", sortable: true, tdClass: "align-middle"},
        {key: "action", label: "", tdClass: "align-middle"}
      ],
      allRecords: [],
      records: [],
      sortBy: "recordId",
      sortDesc: true,

      rows: 0,
      currentPage: 1,
      perPage: 8
    }
  },
  mounted() {
    axios.get("api/item/user?", {params: {"current": true}})
    .then((response) => {
      const records = response.data
      for (let i in records) {
        const record = records[i]
        this.allRecords.push({
          recordId: record["record_id"],
          serial: record["serial"],
          studentId: record["student_id"],
          room: record["room"],
          itemType: record["item_type"],
          itemName: record["item_name"],
          loanDate: record["loan_date"] ? record["loan_date"] : "",
          returnDate: record["return_date"] ? record["return_date"] : "",
          reason: record["reason"],
          comment: record["comment"],
          status: record["status"]
        });
      }
      this.records = this.allRecords;
      this.rows = this.records.length;
    }).catch((error) => {
      if (error.response.status === 404) {
        this.result = error.response.data.msg;
      } else {
        window.alert(error);
      }
    });
  },
  methods: {
    download() {
      return `${
        this.href
      }?is_applying=${this.isApplying.toString()}&active=${this.active.toString()}&item_type=${
        this.excelItemType === null ? '全部' : this.excelItemType
      }`
    },
    searchStudentId() {
      this.loading = true;
      this.studentId = this.studentId.replaceAll(" ", "");
      this.user = {
        name: "", dorm: "", room: "", bed: ""
      };
      this.result = "";
      this.records = [];
      this.currentPage = 1;

      if (this.studentId === "") {
        this.records = this.allRecords;
        this.loading = false;
        return;
      } else if (this.studentId.length > 8) {
        this.studentId = this.studentId.substr(0, 8);
      }

      axios.get("api/item/user/" + this.studentId)
      .then((response) => {
        for (let i in this.user) {
          this.user[i] = response.data[i];
        }
        const records = [].concat.apply([],
          [response.data["old_records"], response.data["active_records"], response.data["applying_records"]]
        );
        for (let i in records) {
          const record = records[i]
          this.records.push({
            recordId: record["record_id"],
            serial: record["serial"],
            studentId: this.studentId,
            room: this.user.room,
            itemType: record["item_type"],
            itemName: record["item_name"],
            loanDate: record["loan_date"] ? record["loan_date"] : "",
            returnDate: record["return_date"] ? record["return_date"] : "",
            reason: record["reason"],
            comment: record["comment"],
            status: record["status"]
          });
        }
        this.placeholder = this.studentId;
        this.studentId = "";
        this.rows = this.records.length;
        this.result = this.records.length ? "" : "沒有借用紀錄";
      }).catch((error) => {
        if (error.response.status === 404) {
          this.result = error.response.data.msg;
        } else {
          window.alert(error);
        }
      });
      this.loading = false;
    },
    recover(recordId) {
      this.$bvModal.msgBoxConfirm("確定要恢復嗎？", {
        title: '確認',
        okVariant: 'warning',
        okTitle: '確定',
        cancelTitle: '取消',
        centered: true
      })
      .then(value => {
        if (value) {
          axios.patch("/api/item/recover/" + recordId)
          .then(() => {
            // window.location.reload();
            this.searchStudentId();
          }).catch((error) => window.alert(error));
        }
      });
    },
    itemFilter(item, filter) {
      if (this.search.selected === null) {
        for (const col in item) {
          if (typeof (item[col]) !== "string") item[col] = String(item[col]);
          const searchFlag = filter[0].length ? item[col].includes(filter[0]) : true;
          const typeFlag = filter[1] ? item.itemType === filter[1] : true;
          if (searchFlag && typeFlag)
            return true;
        }
        return false;
      }
      const searchFlag = filter[0].length ? item[this.search.selected].includes(filter[0]) : true;
      const typeFlag = filter[1] ? item.itemType === filter[1] : true;
      return searchFlag && typeFlag;
    },
    onFiltered(items) {
      this.rows = items.length;
      this.currentPage = 1;
    },
    warn(item, type) {
      if (type !== "row") return;

      if (item.room === "") {
        return "table-danger";
      }

      const diff = new Date() - new Date(item.loanDate);
      if (item.status === "借用中") {
        if (item.itemType === "鑰匙" && diff / (1000 * 60) >= 10)
          return "table-danger";
        else if (item.itemType === "早離卡" && diff / (1000 * 60 * 60 * 24) >= 1)
          return "table-danger";
        else if (item.itemType === "臨時卡" && diff / (1000 * 60 * 60 * 24) >= 30)
          return "table-danger";
        else if (item.itemType === "推車" && diff / (1000 * 60 * 60) >= 1)
          return "table-danger";
      }
    },
    sortCompare(aRow, bRow, key, sortDesc) {
      if (key === "loanDate" || key === "returnDate") {
        const date = new Date(8640000000000000 * (sortDesc ? -1 : 1)); // if date is empty, set to maximum/minimum.
        const a = aRow[key] === null ? date : Date.parse(aRow[key]);
        const b = bRow[key] === null ? date : Date.parse(bRow[key]);
        return a - b;
      } else {
        return false;
      }
    }
  },
  computed: {
    filter() {
      return [this.search.word, this.itemType];
    }
  },
  filters: {
    formatDate(str) {
      if (!str) return "";
      const date = new Date(str).toISOString();
      return date.slice(0, 10) + " " + date.slice(11, 19);
    }
  }
}
</script>

<style scoped>

</style>
